import React, { useState, useEffect } from "react";
import Papa from "papaparse";
  
const Past_Participants = () => {

  const [particData, setParticData] = useState([]);
  const getParticipants = async () =>{
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vQCTBXKlibqbN3H6L4rAlmXoSF_8Y6PfO4HRxg1Xupn6YJ0WwS654CQHKdEwsP73g/pub?output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        
        setParticData(results.data.slice(5,62))
      },
    });
  }

  useEffect(() =>{
    getParticipants();
    console.log("get courses ran")
  }, []);

  useEffect(() =>{
    console.log("course data updated")
    console.log(particData)
  }, [particData])


  return (
    <div class="mainContent">
      <h1>Past Roster</h1>
      <p class="overviewText" style={{textAlign: "center"}}>The table below provides a listing of past participants; 
        number of years of participation, and total rounds played in the MBGolfers Invitational at Oglebay as of 2024.<br></br><br></br>A green box denotes a Hall of Fame Member</p>
      <table class="pastParticTable">
        <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Total Rounds Played</th>
            <th>Number of Years</th>
            <th>Years Participated</th>
          </tr>
        </thead>
        <tbody>
          {particData.map(person =>{
            return (
              <tr>
                <td class={person._6}>{person._1}</td>
                <td class={person._6}>{person._2}</td>
                <td class={person._6}>{person._3}</td>
                <td class={person._6}>{person._4}</td>
                <td class={person._6}>{person._5}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};
  
export default Past_Participants;