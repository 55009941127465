import React, { useState } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Navbar() {

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const coursesClick = () => {
    setDropdownVisible(!isDropdownVisible);
    console.log(isDropdownVisible);
  }

  const [reviewsVisible, setReviewsVisible] = useState(false);

  const reviewsClick = () => {
    setReviewsVisible(!reviewsVisible);
    console.log(reviewsVisible);
  }

  const [teetimeVisible, setTeeTimeVisible] = useState(false);

  const playClick = () => {
    setTeeTimeVisible(!teetimeVisible);
    console.log(teetimeVisible);
  }

  

  // const handleMouseEnter = () => {
  //   setDropdownVisible(true);
  // };

  // const handleMouseLeave = () => {
  //   setDropdownVisible(false);
  // };

  const CoursesMenu = () => {
    return(
      <div>
        <ul>
          <li>
            <a class="coursesNav" href="/courses2024">2024 Courses</a>
          </li>
          <li>
            <a class="coursesNav" href="/courses2023">2023 Courses</a>
          </li>
        </ul>
      </div>
    )
  }

  const ReviewsMenu = () => {
    return(
      <div>
        <ul>
          <li>
            <a class="coursesNav" href="/reviews2024">2024 Reviews</a>
          </li>
          <li>
            <a class="coursesNav" href="/reviews2023">2023 Reviews</a>
          </li>
        </ul>
      </div>
    )
  }

  const PlayMenu = () => {
    return(
      <div>
        <ul>
          <li>
            <a class="coursesNav" href="/play_opportunities">mbgolfers</a>
          </li>
          <li>
            <a class="coursesNav" href="/charity_events">Charity</a>
          </li>
          <li>
            <a class="coursesNav" href="/golf_pass">Golf Pass</a>
          </li>
        </ul>
      </div>
    )
  }


  return (
    <nav>
      <ul>
        <li>
          <a href="/">
            <div class="navLogo"></div>
          </a>
        </li>
        <li class="coursesLi">
          <div class="dropdownMenu">
            <a onClick={playClick}>Play</a>
            {teetimeVisible && <PlayMenu />}
          </div>
        </li>
        <li class="coursesLi">
          <div class="dropdownMenu">
            <a onClick={coursesClick}>Courses</a>
            {isDropdownVisible && <CoursesMenu />}
          </div>
        </li>
        <li class="coursesLi">
          <div class="dropdownMenu">
            <a onClick={reviewsClick}>Reviews</a>
            {reviewsVisible && <ReviewsMenu />}
          </div>
        </li>

        <li>
          <a href="/oglebay">
            Oglebay
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;