import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Layout from "./pages/layout";
import Home from "./pages/home";
import Gallery from "./pages/gallery";
import Courses2023 from "./pages/courses2023";
import Courses2024 from "./pages/courses2024";
import Oglebay_Invitational from "./pages/Oglebay/oglebay_invitational";
import Overview from "./pages/Oglebay/overview";
import Hall_Of_Fame from "./pages/Oglebay/hall_of_fame";
import Play_Opportunities from "./pages/play_opportunities";
import Charity_Events from "./pages/charity_events";
import Results from "./pages/results";
import Add_Event from "./pages/add_event";
import Event_Details from "./pages/Oglebay/event_details";
import Past_Participants from "./pages/Oglebay/past_participants";
import Ogb_Gallery from "./pages/Oglebay/ogb_gallery";
import Reviews2023 from "./pages/reviews2023";
import Reviews2024 from "./pages/reviews2024";
import SFXGolfLeague from "./pages/sfx_golf";
import SFX_Event from "./pages/sfx_add_event";
import Ogb2024Play from "./pages/Oglebay/Play2024";
import Event_Details2024 from "./pages/Oglebay/2024event_details";
import Signup2025 from "./pages/Oglebay/2025SignupPage";
import GolfPass from "./pages/golf_pass";

// const configuration: ConfigurationOptions = {
//   region: 'us-east-1',
  
// }

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="courses2023" element={<Courses2023 />} />
          <Route path="courses2024" element={<Courses2024 />} />
          <Route path="reviews2023" element={<Reviews2023 />} />
          <Route path="reviews2024" element={<Reviews2024 />} />
          <Route path="oglebay" element={<Oglebay_Invitational />} />
          <Route path='oglebay/hall_of_fame' element={<Hall_Of_Fame/>}/>
          <Route path='oglebay/overview' element={<Overview/>}/>
          <Route path='oglebay/event_details' element={<Event_Details/>}/>
          <Route path='oglebay/event_details2024' element={<Event_Details2024/>}/>
          <Route path='oglebay/signup_2025' element={<Signup2025/>}/>
          <Route path='oglebay/Ogb2024Play' element={<Ogb2024Play/>}/>
          <Route path='oglebay/past_participants' element={<Past_Participants/>}/>
          <Route path='oglebay/gallery' element={<Ogb_Gallery/>}/>
          <Route path="play_opportunities" element={<Play_Opportunities />} />
          <Route path="charity_events" element={<Charity_Events />} />
          <Route path="sfx_golf" element={<SFXGolfLeague />} />
          <Route path="golf_pass" element={<GolfPass />} />
          <Route path="sfxweofijh" element={<SFX_Event />} />
          <Route path="results" element={<Results />} />
          <Route path="eiekdoflejfo" element={<Add_Event />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { Nav, NavLink, NavMenu } from './NavbarElements';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
